var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-chip-group",
        {
          attrs: {
            mandatory: "",
            "show-arrows": "",
            "active-class": "primary--text",
          },
          on: { click: _vm.getDoctorsById },
          model: {
            value: _vm.selectedCategory,
            callback: function ($$v) {
              _vm.selectedCategory = $$v
            },
            expression: "selectedCategory",
          },
        },
        _vm._l(_vm.categories, function (category) {
          return _c("v-chip", { key: category.id }, [
            _vm._v(" " + _vm._s(category.value) + " "),
          ])
        }),
        1
      ),
      _vm.items && _vm.items.length > 0
        ? _c(
            "div",
            _vm._l(_vm.items, function (item, i) {
              return _c(
                "v-card",
                {
                  key: i,
                  staticClass:
                    "my-3 pa-3 d-flex flex-row justify-space-between align-center border rounded-lg",
                  attrs: { flat: "", to: `/c/specialist/${item.id}` },
                },
                [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-avatar",
                          { attrs: { size: "72" } },
                          [
                            item.pic
                              ? _c("v-img", {
                                  attrs: {
                                    "lazy-src": require("@/assets/images/profile-circle.png"),
                                    src: item.pic,
                                    alt: item.firstname + " " + item.lastname,
                                  },
                                })
                              : _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/images/profile.jpg"),
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "ms-3 me-0" }, [
                      _c(
                        "span",
                        { staticClass: "d-block f15 font-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(item.firstname) + " " + _vm._s(item.lastname)
                          ),
                        ]
                      ),
                      item.specialties && item.specialties.length
                        ? _c("span", { staticClass: "f13" }, [
                            _vm._v(_vm._s(item.specialties[0].value)),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "f13 primary--text d-block" }, [
                        _vm._v(_vm._s(item.clinic.name)),
                      ]),
                    ]),
                  ]),
                  !item.isFollowed
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2 rounded-lg",
                          attrs: { elevation: "0" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.follow(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Follow")))]
                      )
                    : item.isFollowed
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2 rounded-lg",
                          attrs: { elevation: "0", color: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.unfollow(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Followed")))]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }